<template>
    <ion-page>
        <ion-content padding class="page-yield-patient">
            <ion-card class="page-yield-patient__container">
                <ion-card-content>
                    <IconExchange class="mx-auto d-block mt-4" />
                    <ion-card-title class="text-center mt-4">
                        <strong>{{ $t("yieldPatient.transferManage") }}</strong>
                    </ion-card-title>
                    <div v-if="!showQrCode">
                        <div
                            class="page-yield-patient__container-patient mt-3 text-center"
                        >
                            <div
                                class="page-yield-patient__container-patient-title"
                            >
                                <strong>
                                    {{ selectedPatient.firstName }}
                                    {{ selectedPatient.lastName }}
                                </strong>
                            </div>
                            <div>
                                {{
                                    selectedPatient.unit.emergencyRoom
                                        ? $t("relativesListPage.emergencyRoom")
                                        : $t("relativesListPage.unit")
                                }}:

                                <span> {{ selectedPatient.unit.name }}</span>
                            </div>
                        </div>
                        <div class="text-center mt-4">
                            <span v-html="$t('yieldPatient.transferDesc')" />
                        </div>
                        <div v-if="!transferResponse" class="text-center mt-3">
                            <ion-button
                                fill="outline"
                                color="medium"
                                class="custom-button text-lowercase"
                                @click="acceptTransfer(false)"
                            >
                                {{ $t("yieldPatient.showCode") }}
                            </ion-button>
                            <ion-button
                                fill="outline"
                                color="medium"
                                class="custom-button ml-1"
                                @click="acceptTransfer(true)"
                            >
                                <ion-icon slot="icon-only" :icon="qrCode" />
                            </ion-button>
                        </div>
                        <div
                            class="d-flex justify-content-center align-items-center mt-3"
                            v-else
                        >
                            <div class="page-yield-patient__container-token">
                                <span>{{ transferResponse.token }}</span>
                            </div>
                            <ion-button
                                fill="outline"
                                class="ml-1"
                                @click="showQrCode = true"
                            >
                                <ion-icon slot="icon-only" :icon="qrCode" />
                            </ion-button>
                        </div>
                        <ion-button
                            color="primary"
                            class="w-100 mt-5 mb-4"
                            @click="goTo('page-relatives-list')"
                        >
                            {{ $t("yieldPatient.backToHome") }}
                        </ion-button>
                    </div>
                    <div v-else>
                        <div class="text-center mt-3">
                            {{ $t("yieldPatient.qrCodeDesc") }}
                        </div>
                        <img class="w-100" :src="transferResponse.qrCode" />
                        <ion-button
                            color="primary"
                            class="w-100 mb-1"
                            @click="showQrCode = false"
                        >
                            {{ $t("close") }}
                        </ion-button>
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./YieldPatient.ts"></script>
<style lang="scss" src="./YieldPatient.scss"></style>
