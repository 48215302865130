import { Vue, Options } from 'vue-class-component';
import IconExchange from '@/views/components/icons/IconExchange.vue';
import { qrCode } from 'ionicons/icons';
import { mapState } from 'vuex';
import {
    IRequestPatientTransferRequest,
    Patient,
    RequestPatientTransferRequest,
    RequestPatientTransferResponse,
} from '@/../client-generator/generated-clients/api-clients';
import { alertController } from '@ionic/vue';
import { message } from '@/core/utilities/utilities';
import transferClient from '@/core/api/transfer-service';

@Options({
    name: 'YieldPatient',
    components: {
        IconExchange,
    },
    computed: {
        ...mapState({ selectedPatient: 'selectedPatient' }),
    },
})
export default class YieldPatient extends Vue {
    qrCode = qrCode;
    qrCodeRead = false;
    selectedPatient!: Patient;
    transferResponse: RequestPatientTransferResponse | null = null;
    message = message;
    showQrCode = false;

    async acceptTransfer(isQr: boolean) {
        const alert = await alertController.create({
            cssClass: 'my-custom-alert',
            header: this.$t('yieldPatient.confirmTransfer'),
            inputs: [
                {
                    name: 'confirmValue',
                    type: 'checkbox',
                    label: this.$t('yieldPatient.alertMessage'),
                    value: 'confirmValue',
                    checked: false,
                },
            ],
            buttons: [
                {
                    text: this.$t('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Ok',
                    handler: (data) => {
                        if (data.length != 0) {
                            this.requestTransfer(isQr);
                        } else {
                            this.message(this.$t('yieldPatient.haveToAccept'));
                        }
                    },
                },
            ],
        });
        return alert.present();
    }

    async requestTransfer(isQr: boolean) {
        if (!this.selectedPatient.id) return;
        try {
            const data: IRequestPatientTransferRequest = { confirmTermsAndAgreements: true };
            this.transferResponse = await transferClient.requestPatientTransfer(
                new RequestPatientTransferRequest(data),
                this.selectedPatient.id.toString()
            );
            this.showQrCode = isQr;
        } catch (error) {
            this.message(this.$t('apiError'));
        }
    }

    goTo(route: string) {
        this.$router.replace({
            name: route,
        });
    }
}
